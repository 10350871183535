.general-alert-box {
  position: fixed;
  z-index: 999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
  min-width: 450px;
  background: white;
  box-shadow: rgb(55, 55, 55) 2px 2px 2px;
  border: rgb(55, 55, 55) solid 1px;
}
@media (max-width: 500px) {
  .general-alert-box {
    width: 90%;
    min-width: 90%;
  }
}
.general-alert-box h1 {
  display: flex;
  align-items: center;
  margin: 0;
  background-color: rgb(55, 55, 55);
}
.general-alert-box h1 span {
  color: white;
}
.general-alert-box h1 img {
  height: 1.25em;
  margin-right: 0.25em;
}
.good-style {
  filter: invert(85%) sepia(21%) saturate(7000%) hue-rotate(65deg)
    brightness(87%) contrast(90%);
}
.neutral-style {
  filter: brightness(0) saturate(100%) invert(97%) sepia(85%) saturate(3059%)
    hue-rotate(321deg) brightness(108%) contrast(101%);
}
.error-style {
  filter: invert(18%) sepia(99%) saturate(7449%) hue-rotate(2deg)
    brightness(95%) contrast(113%);
}
.alert-body {
  padding: 0 1em 1em 1em;
}
.alert-button-div {
  display: flex;
  justify-content: right;
  flex-wrap: wrap;
}
.alert-button-div button {
  margin-top: 1em;
  margin-left: 1em;

  background-color: rgb(55, 55, 55);
}

.alert-message {
  white-space: pre-wrap;
}
