.footer {
  background-color: rgb(85, 85, 85);
  color: white;
  padding: 1.5em 2em 2em 2em;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  line-height: 1.5;
  margin-bottom: 1em;
}
.footer h3 {
  color: white;
  margin: 0.5em 0 0 0;
}
.footer ul {
  margin: 0;
}
.footer-block {
  width: 25%;
  min-width: 13em;
}

.footer-block-content {
  display: flex;
  justify-content: space-between;
  padding-right: 0.25em;
}
.footer-some-links {
  justify-content: left;
}
.footer-some-links a {
  margin-right: 1em;
}
.footer-block ul {
  padding: 0;
  list-style-type: none;
}
.footer-block a {
  color: rgb(204, 204, 204);
}
.footer-block a:hover {
  text-decoration: none;
  border: none;
}

.footer-block a img {
  width: 2em;
  filter: brightness(0) saturate(100%) invert(88%) sepia(0%) saturate(158%)
    hue-rotate(178deg) brightness(91%) contrast(100%);
}

.footer-block a img:hover {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(5670%)
    hue-rotate(234deg) brightness(123%) contrast(80%);
}
