.personal-info-item {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: stretch;
  flex-grow: 2;
  margin: 0.25em;
}
.personal-info-item p {
  display: flex;
  align-items: center;
  margin: 0;
  width: 12em;
}
.personal-information-form p img {
  height: 1em;
  margin-right: 0.5em;
  filter: invert(11%) sepia(75%) saturate(3857%) hue-rotate(334deg)
    brightness(93%) contrast(90%);
}
.input {
  flex-grow: 3;
}
input:disabled {
  border-color: transparent;
  background: transparent;
}
.country-select {
  height: max-content;
  cursor: pointer;
  -webkit-appearance: none;
  border-radius: 0;
}
.country-select:disabled {
  border-color: transparent;
  background: transparent;
  cursor: auto;
}

.two-part-label {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.first-label {
}

.first-label p {
  width: auto;
}
.postcode-input {
  width: max-content;
  min-width: max-content;
  flex-grow: 0;
}
.city-input {
  display: flex;
  flex-grow: 999;
  flex-wrap: wrap;
}
/********************************/
.controls {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 1em;
}
.edit-controls * {
  width: 100%;
  margin-top: 1em;
}

.checkbox {
  margin-top: 0.5em;
}
