.home-navigation-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: stretch;
  align-items: stretch;
}
.home-nav-item {
  display: flex;
  flex-direction: column;
  min-width: 18em;
  width: 45%;
  align-items: stretch;
  flex-grow: 2;
  margin: 0.5em 0.5em;
  padding: 0.25em;
  background-color: rgb(240, 240, 240);
  border: none;
  text-decoration: none;
}
.home-nav-item:hover {
  text-decoration: none;
  border-bottom: none;
}
.home-nav-item p {
  flex-grow: 999;
  text-align: start;
  text-decoration: none;
}
.title-tile {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: rgb(190, 220, 230);

  min-width: 9em;
  width: 9em;
  height: 6em;
  padding: 0.25em;
  margin: 0.125em;
}
.title-tile img {
  padding: 0.25em;
  min-height: 2em;
  flex-grow: 999;

  height: 100%;
  width: 100%;
  filter: invert(100%);
}
.title-tile span {
  color: rgb(85, 85, 85);
  text-align: center;
}
#placeholder-button {
  padding: 0;
  cursor: default;
}

#placeholder-button:active {
  padding: 0;
  cursor: default;
  animation: none;
}
