.header-collapsible {
  padding: 1em 0 1em 0;
  border-top: 2px solid rgb(150, 25, 50);
  border-bottom: 1px solid lightgray;
}
.header-language-selection {
  display: flex;
  flex-direction: column;
}
.collapsible-button {
  display: flex;
  justify-content: center;
  border: none;
  background-color: rgb(240, 240, 240);
  font-size: medium;
  padding: 0.5em;
  margin: 0.1em;
}

.collapsible-button:hover,
.collapsible-button:focus {
  font-weight: bold;
}

.collapsible-button:active {
  transform: translateY(1px);
  filter: saturate(120%);
}
.collapsible-button img {
  height: 1em;
  margin-right: 0.5em;
  margin-left: 2em;
}

.collapsible-button span {
  width: 6em;
  text-align: left;
}
@media (min-width: 501px) {
  .header-language-selection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  .collapsible-logout {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}
.main-navigation {
  margin-top: 0.5em;
  display: flex;
  justify-content: space-around;
  margin-bottom: 1em;
  flex-wrap: wrap;
}
.main-navigation .collapsible-button {
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: rgb(235, 230, 215);

  text-decoration: none;
  border-bottom: none;
  width: 7em;
  height: 5em;
  padding: 0.5em;
}
.main-navigation .collapsible-button span {
  color: rgb(85, 85, 85);
  text-align: center;
  width: inherit;
  padding: 0 0.5em;
  font-size: small;
}

.main-navigation .collapsible-button img {
  filter: invert(30%) sepia(0%) saturate(324%) hue-rotate(152deg)
    brightness(100%) contrast(84%);
  margin: 0.25em;
  flex-grow: 9;
  width: 100%;
}
