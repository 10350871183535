.entire-login-container {
  display: flex;
  flex-direction: column;
  align-content: center;
}
.image-container {
  width: 100%;
  align-content: center;
  display: flex;
}
.image-container img {
  width: 70%;
  object-fit: scale-down;
  height: 100%;
}
.login-options-container {
  flex-grow: 2;
}

.registration-area {
  background-color: rgb(240, 240, 240);
  padding: 1.5em 2em 2em 2em;
}
.registration-area p {
  margin-bottom: 0;
}

.image-text-block {
  width: 30%;
  background-color: rgb(150, 25, 50);
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-text-block span {
  color: white;
  font-size: x-large;
  text-align: center;
  padding: 0.5em;
}
@media (max-width: 500px) {
  .image-container img {
    width: 100%;
    justify-self: center;
  }
  .image-text-block {
    display: none;
  }
}
