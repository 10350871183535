.update-password-content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.ambient-image {
  object-fit: scale-down;
  flex-grow: 0;
  max-height: 100%;
  height: 15em;
}
@media (max-width: 1022px) {
  .ambient-image {
    display: none;
  }
}
.forgot-password-form {
  display: flex;
  flex-direction: column;
  background: rgb(240, 240, 240);
  padding: 1.5em 2em 2em 2em;
  flex-grow: 999;
  height: 15em;
}
.forgot-password-intro {
  background-color: rgb(240, 240, 240);
  padding: 1.5em 2em 2em 2em;
}
.forgot-password-box {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-wrap: wrap;
  margin-bottom: 1em;
}
.forgot-password-box-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0.25em;
  margin-right: 0.5em;
  font-size: large;
}
.forgot-password-box-info img {
  margin-right: 0.5em;
}

.apply-for-new-password-box {
  display: flex;
  justify-content: left;
  align-items: center;
  flex-wrap: wrap;
}
.forgot-password-submit-button {
  align-self: flex-end;
}
.forgot-password-submit-button:disabled {
  background-color: rgb(85, 85, 85);
}
.forgot-password-confirmation {
  margin: 0em 2em 2em 2em;
}
