.entire-header {
  display: flex;
  flex-direction: column;
  padding-top: 1em;
  max-width: 100%;
}

.logo-and-settings {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.header-logo-link {
  height: 100%;
  width: 15em;
  max-width: 50%;
}

.header-img {
  width: 1.5em;
  margin: 0 0.5em;
}

.nav-item {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-left: 1.5em;
  text-decoration: none;
}
.last-nav-item {
  margin-right: 0;
}

.header-logo-img {
  max-height: 100%;
  max-width: 100%;
  min-width: 30mm;
}
.header-language-area {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.language-option {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.language-option img {
  margin-right: 10px;
}

.header-settings {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: start;
  height: 100%;
  flex-grow: 999;
}
.settings-only {
  display: flex;
  flex-wrap: wrap;
  justify-content: right;
}
.header-settings-button {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  border: none;
  background-color: transparent;
  text-align: right;
  font-size: small;
  padding: 0;
  margin: 0 0.5em;
}

@media (max-width: 500px) {
  .logo-and-settings {
    flex-direction: row-reverse;
  }
  .settings-only {
    flex-direction: column;
  }
  .header-settings-button {
    text-align: left;
  }
  .header-settings {
    flex-direction: column;
    margin-top: 0;
    align-items: flex-start;
  }
}
